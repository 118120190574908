import { Link } from '@chakra-ui/react'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { split } from 'ramda'
import { AccountLink } from '../AccountLink'

function ActorType({ type, id, title }: { type: string; id: string; title?: string }) {
  switch (type) {
    case 'account':
    case 'user': {
      if (id.includes('@')) {
        return (
          <Link as={RouterLink} to={`/Users?search=${id}`} title={title}>
            {id}
          </Link>
        )
      }
      return <AccountLink newTab id={id} showName title={title} />
    }
  }
  return <span title="Actor">{`${type}:${id}`}</span>
}

export function Actor({ actor }: { actor: string | null }) {
  if (!actor) {
    return null
  }
  if (actor.includes(':')) {
    const [type, ...selectors] = actor.split(':')
    return <ActorType type={type} id={selectors[0]} title="Actor" />
  }
  if (actor.includes('=')) {
    let actors = actor.split(',').map(split('='))
    const account = actors.find(([type]) => type === 'account')
    const user = actors.find(([type]) => type === 'user')
    let combined = null
    if (account && user) {
      if (account[1] === user[1]) {
        actors = actors.filter(([type]) => type !== 'account')
      } else {
        actors = actors.filter(([type]) => type !== 'account' && type !== 'user')
        combined = (
          <span title="Actor">
            <ActorType type="user" id={user[1]} />
            {' logged in as '}
            <ActorType type="account" id={account[1]} />
          </span>
        )
      }
    }
    return (
      <>
        {combined}
        {actors.map(([type, id = '']) => {
          return <ActorType key={type + id} type={type} id={id} title="Actor" />
        })}
      </>
    )
  }
  return <span title="Actor">{actor}</span>
}
