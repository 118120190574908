export enum StorageItemType {
  SMALL = 'SMALL',
  LARGE = 'LARGE',
  BOX = 'BOX',
}

export enum DeliveryTimeSlots {
  _1000__1600 = '10:00 - 16:00',
  _1600__2200 = '16:00 - 22:00',
}

export enum TransportStatus {
  REQUESTED = 'REQUESTED',
  COLLECTED = 'COLLECTED',
  DELIVERED = 'DELIVERED',
}

export enum TransportType {
  TO_CLIENT = 'TO_CLIENT',
  TO_WAREHOUSE = 'TO_WAREHOUSE',
}

export enum OrderState {
  ORDER_SUBMITTED = 'ORDER_SUBMITTED',
  PAYMENT_AUTHORISED = 'PAYMENT_AUTHORISED',
  REJECTED = 'ORDER_REJECTED',
  DEVIATED = 'DEVIATED',
  SHIPMENT_HANDOVER_COMPLETED = 'SHIPMENT_HANDOVER_COMPLETED',
  ORDER_FULFILLED = 'ORDER_FULFILLED',
}

export enum OrderType {
  PICK_UP = 'PICK_UP',
  DELIVERY = 'DELIVERY',
}

export enum OrderDetailsTabs {
  ACTIONS = 'actions',
  ITEMS = 'items',
  DETAILS = 'details',
}

export enum SupportedCountries {
  NO = 'NO',
  SE = 'SE',
}

export enum SupportedCities {
  NO_OSLO = 'NO_OSLO',
  NO_STAVANGER = 'NO_STAVANGER',
  NO_TRONDHEIM = 'NO_TRONDHEIM',
  SE_STOCKHOLM = 'SE_STOCKHOLM',
  SE_GOTHENBURG = 'SE_GOTHENBURG',
}

export enum CategoryType {
  CAT1 = 1,
  CAT2 = 2,
  CAT3 = 3,
  CAT4 = 4,
}

export interface CategoryRange {
  from: number
  to: number
  type: StorageItemType
}

type CategoryLeadTime = '2' | '3' | '4' | 'ASK'

export interface Category {
  type: CategoryType
  leadTimeDays: CategoryLeadTime
  ranges: CategoryRange[]
  minimumStorageMonths?: number
}

export enum WarehouseTasks {
  CHARGE = 'Charge',
  BREAK = 'Break',
  WASH_CAR = 'Wash car',
}

export enum ProductTarget {
  USER = 'USER',
  ITEM = 'ITEM',
}

export enum ProductType {
  STORAGE = 'STORAGE',
  TAAS = 'TAAS',
  SERVICE = 'SERVICE',
  USER_FEATURE = 'USER_FEATURE',
  ADD_ON = 'ADD_ON',
}

export enum ProductPriceType {
  RECURRING = 'RECURRING',
  ONE_TIME = 'ONE_TIME',
}

export enum OrderHandoverSteps {
  LABEL_VERIFICATION = 'LABEL_VERIFICATION',
  ITEM_CREATION = 'ITEM_CREATION',
}
