import { request } from '@wanda/shared/src/api-client/api-client'
import { fetchChunk } from '@wanda/shared/src/api-client/chunk'

import { opsBaseUrl as baseUrl } from 'api/config'
import type { Warehouse } from 'interface/warehouse'

export const getWarehouses = async (params: {
  search: string | undefined
  filters: Record<string, string[] | string | undefined>
  token: string
}) => {
  return fetchChunk<Warehouse>({
    url: '/v1/warehouses/',
    orderBy: 'name',
    orderDir: 'ASC',
    itemsPerPage: 100,
    page: 1,
    baseUrl,
    ...params,
  })
}
export const getWarehouse = async ({ token, id }: { token: string; id: string }) => {
  return await request<Warehouse>({
    method: 'GET',
    url: `/v1/warehouses/${id}/`,
    token,
    baseUrl,
  })
}

export type WarehouseLocation = {
  id: string
  warehouse: string
  location: string
  spot?: string | null
  areaCode?: string | null
  label: string
}

export const getWarehouseLocation = async ({
  warehouseId,
  ...params
}: {
  warehouseId: string | undefined
  search: string | undefined
  filters: Record<string, string[] | string | undefined>
  token: string
}) => {
  return fetchChunk<WarehouseLocation>({
    url: warehouseId ? `/v1/warehouses/${warehouseId}/locations/` : '/v1/warehouse-locations/',
    orderBy: 'name',
    orderDir: 'ASC',
    itemsPerPage: 5000,
    page: 1,
    baseUrl,
    ...params,
  })
}
