import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import type { InboundItemDto } from '@wanda-space/ops-types'
import type { ApiFilters } from '@wanda/shared/src/api-client/chunk'
import { validate } from 'uuid'

import { fetchOpsItems, getListOfImages } from 'api/routes/items'

export function isValidItemId(itemId: string | undefined | null) {
  if (!itemId) {
    return false
  }
  if (validate(itemId)) {
    return true
  }
  if (/\d+-[\da-fA-F]{4}/.test(itemId)) {
    return true
  }
  if (/\d+-[\da-fA-F]{3}/.test(itemId)) {
    return true
  }
  if (itemId?.startsWith('#')) {
    return true
  }
  return false
}

async function fetch({
  itemId,
  token,
}: {
  itemId: string | undefined | null
  token: string
}): Promise<InboundItemDto> {
  let filters: ApiFilters = {}
  if (!itemId) {
    throw new Error('Missing item id')
  }
  if (/^\d+-[\da-fA-F]{4}$/.test(itemId)) {
    filters = { simpleId: itemId.split('-')[0] }
  } else if (/^\d+-[\da-fA-F]{3}$/.test(itemId)) {
    filters = { oldOpsId: itemId }
  } else if (/^#?\d{3}\d+$/.test(itemId)) {
    filters = { simpleId: itemId.replace('#', '') }
  } else if (validate(itemId)) {
    filters = { id: itemId }
  } else {
    throw new Error(`Invalid item id ${itemId}`)
  }

  const opsResponse = await fetchOpsItems({
    filters,
    itemsPerPage: 1,
    page: 1,
    token,
  })
  if (opsResponse.count === 0) {
    throw new Error('Did not find item')
  }
  if (opsResponse.count > 1) {
    throw new Error('To many items returned')
  }
  const [opsItem] = opsResponse.items

  return opsItem
}

export function useItem(
  itemId: string | undefined | null,
  options?: { refetchOnMount?: boolean; suspense?: boolean },
) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['item', itemId],
    async () => fetch({ itemId, token: await getAccessTokenSilently() }),
    {
      ...options,
      enabled: isValidItemId(itemId),
    },
  )
}

export function useItemImages(
  itemId: string | undefined | null,
  options?: { refetchOnMount?: boolean; suspense?: boolean },
) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['item-images', itemId],
    async () => {
      const images = await getListOfImages(itemId!, await getAccessTokenSilently())
      return images.map((i) => i.split('/')[1])
    },
    {
      retry: false,
      ...options,
      enabled: !!itemId,
    },
  )
}
