import { Box, HStack, Text, useColorModeValue } from '@chakra-ui/react'
import type { Diff } from 'deep-diff'
import React, { type ReactNode } from 'react'

import { OrderLink } from '../../components/OrderLink'
import { AccountLink } from '../AccountLink'
import { ProductCategory } from '../ProductCategory'
import { ServiceProvider } from '../ServiceProvider'
import { Warehouse } from '../Warehouse/Warehouse'
import { WarehouseLocation } from '../WarehouseLocation/WarehouseLocation'

export function DiffList<LHS, RHS>({
  diffs,
  system,
  showHiddenFields = false,
}: { diffs: Diff<LHS, RHS>[]; system: 'howls-castle' | 'ops-api'; showHiddenFields?: boolean }) {
  return (
    <Box overflowX="auto">
      {diffs?.map((diff, index) => (
        <DiffItem key={index} diff={diff} system={system} showHiddenFields={showHiddenFields} />
      ))}
    </Box>
  )
}

export function isHiddenField(path: string[] | undefined, system: 'howls-castle' | 'ops-api') {
  if (!path) {
    return true
  }
  if (
    ['updated_at', 'updatedAt', 'createdAt', 'deletedAt', 'updatedAtLocation', 'version'].includes(
      path[0],
    )
  ) {
    return true
  }
  if (path[0] === 'storageProduct') {
    return [
      'updated_at',
      'updatedAt',
      'createdAt',
      'deletedAt',
      'updatedAtLocation',
      'version',
    ].includes(path[1])
  }
  if (system === 'howls-castle') {
    return ['warehouse_id'].includes(path[0])
  }
  if (system === 'ops-api') {
    return ['state'].includes(path[0])
  }
  return false
}

function DiffItem<LHS, RHS>({
  diff,
  system,
  showHiddenFields,
}: { diff: Diff<LHS, RHS>; system: 'howls-castle' | 'ops-api'; showHiddenFields: boolean }) {
  if (isHiddenField(diff.path, system) && !showHiddenFields) {
    return null
  }

  switch (diff.kind) {
    case 'E':
      return (
        <Box>
          <HStack>
            <Text fontWeight="semibold">{diff.path?.join('.')}</Text>
            {diff.lhs ? (
              <Removed>
                <Value path={diff.path} value={diff.lhs} />
              </Removed>
            ) : null}
            {diff.rhs ? (
              <Added>
                <Value path={diff.path} value={diff.rhs} />
              </Added>
            ) : null}
          </HStack>
        </Box>
      )
    case 'D':
      return (
        <Box>
          <HStack>
            <Text fontWeight="semibold">{diff.path?.join('.')}</Text>
            <Removed>
              <Value path={diff.path} value={diff.lhs} />
            </Removed>
          </HStack>
        </Box>
      )
    case 'N':
      return (
        <Box>
          <HStack>
            <Text fontWeight="semibold">{diff.path?.join('.')}</Text>
            <Added>
              <Value path={diff.path} value={diff.rhs} />
            </Added>
          </HStack>
        </Box>
      )
    case 'A':
      return (
        <DiffItem
          diff={{ ...diff.item, path: diff.path }}
          system={system}
          showHiddenFields={showHiddenFields}
        />
      )
    default:
      return <pre>{JSON.stringify(diff, null, 2)}</pre>
  }
}

function Value({ path, value }: { path: string[] | undefined; value: unknown }) {
  if (value && path) {
    if (path.includes('location') && typeof value === 'string') {
      return <WarehouseLocation id={value} />
    }
    if (path.includes('warehouse') && typeof value === 'string') {
      return <Warehouse id={value} />
    }
    if (path.includes('serviceProviderId') && typeof value === 'string') {
      return <ServiceProvider id={value} />
    }
    if (path.includes('productCategoryId') && typeof value === 'string') {
      return <ProductCategory categoryId={value} />
    }
    if (
      (path.includes('currently_with') ||
        path.includes('currentlyWith') ||
        path.includes('userId') ||
        path.includes('ownerId') ||
        path.includes('accountId') ||
        path.includes('ownerUserId')) &&
      typeof value === 'string'
    ) {
      return <AccountLink newTab id={value} showId showName />
    }
    if (path.includes('pending_order_id') && typeof value === 'string') {
      return <OrderLink newTab orderId={value} />
    }
  }
  if (typeof value === 'object' && value !== null) {
    return <pre>{JSON.stringify(value, null, 2)}</pre>
  }
  return <>{value}</>
}

const Removed = ({ children }: { children: ReactNode }) => {
  const bg = useColorModeValue('red.200', 'red.700')
  return (
    <Text as="del" title="Removed" background={bg} borderRadius="2px" p="0.5" fontSize="sm">
      {children}
    </Text>
  )
}

function Added({ children }: { children: ReactNode }) {
  const bg = useColorModeValue('green.200', 'green.700')
  return (
    <Text as="span" title="Added" background={bg} borderRadius="2px" p="0.5" fontSize="sm">
      {children}
    </Text>
  )
}
